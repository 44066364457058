import styled from 'styled-components';

const PostContainer = styled.div`
.previewTitle {
    font-size: 1.5em;
}
  
.tag {
    color: #A0A0A0;
    text-decoration: none;
    display: inline-block;
    padding: .33333rem .5rem;
    line-height: 1;
    border-radius: 2px;
    border: 1px solid #A0A0A0;
    margin-right: .5em;
}
`

export default PostContainer;