import React from 'react'
import tw from 'twin.macro';

import { Link, useStaticQuery, graphql } from 'gatsby';
import { Helmet } from 'react-helmet';

import Layout, {Container, ContentWithPaddingXl} from '@components/Layout';
import Header from '@components/Header';
import CookieBanner from '@components/Cookies/CookieBanner';
import PostPreview from '@components/Post/PostPreview';


/*
export const pageQuery = graphql`
  query BlogIndexQuery {
    site {
      siteMetadata {
        title
      }
    }
    allContentfulBlogPost(sort: { fields: [publishDate], order: DESC }) {
      edges {
        node {
          title
          slug
          publishDate(formatString: "MMMM Do, YYYY", locale: "es")
          tags
          heroImage {
            fluid(maxWidth: 350, maxHeight: 196, resizingBehavior: SCALE) {
              ...GatsbyContentfulFluid_tracedSVG
            }
          }
          description {
            childMarkdownRemark {
              html
            }
          }
        }
      }
    }
  }
`
*/

const Hero = tw.div`flex justify-center items-center text-xl`;
const SectionContainer= tw.section`p-32`;
/*
.hero {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 61.8vh;
    max-height: 400px;
    background: #e1e1e1;
    font-size: 2em;
    overflow: hidden;
  }
  */

 export const query = graphql`
    query BlogIndexQuery {
        site {
          siteMetadata {
            title
          }
        }
        allContentfulBlogPost(sort: { fields: [publishDate], order: DESC }) {
          edges {
            node {
              title
              slug
              publishDate(formatString: "DD MMMM, YYYY", locale: "es")
              tags
              heroImage {
                fluid(maxWidth: 350, maxHeight: 196, resizingBehavior: SCALE, toFormat: PNG) {
                  ...GatsbyContentfulFluid
                }
              }
              description {
                childMarkdownRemark {
                  html
                }
              }
            }
          }
        }
      }
    `;

const Blog = props => {


    const siteTitle = props.data.site.siteMetadata.title;
    const posts = props.data.allContentfulBlogPost.edges;

    return (
        <Layout>
            <Header />
            <SectionContainer id="blog">
                <div style={{ background: '#fff' }}>
                <Helmet title={siteTitle} />
                <Hero>Blog</Hero>
                <div className="wrapper">
                    <h2 className="section-headline">Recent articles</h2>
                    <ul className="article-list">
                    {posts.map(({ node }) => {
                        return (
                        <li key={node.slug}>
                            <PostPreview article={node} />
                        </li>
                        )
                    })}
                    </ul>
                </div>
                </div>
            </SectionContainer>    
            <CookieBanner/>
        </Layout>
    );
}

export default Blog;

