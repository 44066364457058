import React from 'react'
import { Link } from 'gatsby'
import Img from 'gatsby-image';

import PostContainer from './post.style';
//.childMarkdownRemark.html
export default ({ article }) => (
    <PostContainer >
        <Img alt="" fluid={article.heroImage.fluid} />
        <h3 className="previewTitle">
        <Link to={`/blog/${article.slug}`}>{article.title}</Link>
        </h3>
        <small>{article.publishDate}</small>
        <div
        dangerouslySetInnerHTML={{
            __html: article.description,
        }}
        />
        {article.tags &&
        article.tags.map(tag => (
            <p className="tag" key={tag}>
            {tag}
            </p>
        ))}
  </PostContainer>
);
